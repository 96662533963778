import React, { useEffect } from 'react';
import pinLocation from 'images/icon/pin-location.svg';
import SlideUp from 'components/animation/SlideUpFade';
import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import CustomContainer from '../../CustomContainer';
import CircularProgress from '../../animation/CircularProgress';

const CustomInput = ({ inputData, placeholder, name, type, handleChange }) => {
  return (
    <input
      defaultValue={inputData[name]}
      required
      pattern={type === 'tel' && '[0-9]{1,}'}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={(e) => handleChange(e, name)}
      className="border-b bg-brand-black w-full lg:w-[75%] text-[13px] lg:text-sm focus:outline-none placeholder:text-sm py-2"
    />
  );
};

const ContactUs = () => {
  const form = React.useRef();
  const [inputData, setInputData] = React.useState({
    full_name: '',
    company_name: '',
    email_address: '',
    phone_number: '',
    message: '',
  });
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const handleChange = (event, inputType) => {
    setInputData({ ...inputData, [inputType]: event.target.value });
  };

  const checkInput = React.useCallback(() => {
    if (
      inputData.full_name.length &&
      inputData.company_name.length &&
      inputData.email_address.length &&
      inputData.phone_number.length &&
      inputData.message.length
    ) {
      setIsDisabled(false);
    }
  }, [inputData]);

  useEffect(() => checkInput(), [checkInput]);

  const sendEmail = async (e) => {
    try {
      e.preventDefault();
      setIsDisabled(true);
      setIsSubmit(true);
      await emailjs.sendForm(
        process.env.EMAILJS_SERVICE_ID,
        process.env.EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.EMAILJS_PUBLIC_KEY,
      );
      form.current.reset();
      setInputData({
        full_name: '',
        company_name: '',
        email_address: '',
        phone_number: '',
        message: '',
      });
      setIsSubmit(false);
    } catch (error) {
      console.log(error.text);
    }
  };

  return (
    <div className="min-h-[84.5vh] bg-brand-black text-brand-white pt-16">
      <div className="pt-12 lg:pt-32">
        <CustomContainer className="flex flex-col lg:flex-row justify-between w-full">
          <div className="flex-1">
            <SlideUp>
              <p className="text-md lg:text-xl font-thin leading-snug">
                Let's talk and go the extra mile together.
              </p>
            </SlideUp>
            <SlideUp>
              <p className="text-[14px] lg:text-base font-thin mt-2">
                Or send us an email at{' '}
                <span className="font-medium">info@saltventures.id</span>
              </p>
            </SlideUp>
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex-1 flex flex-col items-start lg:items-end mt-10 lg:mt-0"
          >
            <div className="space-y-4 flex flex-col items-end w-full">
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Full Name"
                name="full_name"
                type="text"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Company Name"
                name="company_name"
                type="text"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Phone Number"
                name="phone_number"
                type="tel"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Email Address"
                name="email_address"
                type="email"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Message"
                name="message"
                type="text"
              />
            </div>
            <div className="text-[11px] lg:text-[13px] font-thin flex justify-end w-full lg:w-[75%] mb-8 mt-2">
              {inputData.message.length}/1000 Characters
            </div>
            <button
              disabled={isDisabled}
              type="submit"
              className="w-full lg:w-[75%] py-3 text-sm font-thin bg-[#050C14] disabled:bg-[#8B8D92] disabled:text-slate-500 disabled:shadow-none"
            >
              <div className="flex justify-center items-center">
                {isSubmit && <CircularProgress />}
                <span>Send</span>
              </div>
            </button>
          </form>
        </CustomContainer>
        <div className="mt-20 lg:mt-36">
          <CustomContainer className="mb-8">
            <SlideUp>
              <div className="flex items-start w-full lg:w-1/2 ">
                <img
                  src={pinLocation}
                  alt="icon-pin"
                  className="translate-y-[5px]"
                />
                <p className="font-thin lg:text-[18px] text-sm ml-5">
                  Prosperity Tower, Jl. Jend. Sudirman Kav 52-53 No. 5, RW 3,
                  Senayan, Kec. Kebayoran Baru, South Jakarta, DKI Jakarta,
                  12190
                </p>
              </div>
            </SlideUp>
          </CustomContainer>
          <div className="2xl:container 2xl:mx-auto 2xl:px-10">
            <iframe
              width="100%"
              height="500"
              id="gmap_canvas"
              title="address"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.2706897967323!2d106.80271843488771!3d-6.227998599999979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1500e1e0f8f%3A0x22556f795030d081!2sProsperity%20Tower!5e0!3m2!1sen!2sid!4v1656494466641!5m2!1sen!2sid"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CustomInput.propTypes = {
  placeholder: PropTypes.string,
};

export default ContactUs;
