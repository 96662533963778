import React from 'react';
import Pages from '../components/Pages';
import ContactUs from '../components/pages/contactUs';

const ContactUsPage = () => {
  return (
    <Pages>
      <ContactUs />
    </Pages>
  );
};

export default ContactUsPage;
